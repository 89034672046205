<template>
<div>

  <div class=""  style="    background: rgba(171, 0, 94, 0.05)!important;">

    <div class="px-2 " style="height: 100vh!important;">
      <br><br><br>
      <div class="w3-center">
        <div class="w3-display-container">
          <div :style="$vuetify.breakpoint.smAndUp?'fontSize: 224px': 'fontSize:180px'" class="error-header">404 </div>
          <div class="w3-display-middle w3-center mt-2" style="width: 100%">
            <div class="error-subheader" :style="$vuetify.breakpoint.smAndUp?'fontSize: 42px': 'fontSize:24px'">
              page not found
            </div>


          </div>
        </div>
        <p style="font-size: 23px;text-align: center;">
          It looks like you are trying to access a page that has either  been
          <br class="w3-hide-small">  deleted or never  existed.
        </p>
<!--        <router-link class="mylink" :to="{name:'home'}">Let's Take You Home</router-link>-->

      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "notFound",

}
</script>

<style scoped>
.loginButton{
  color: #fff!important;
  background-color: #e70a80!important;
  border-color: #e70a80!important;
}
#notfound{
  height: 100vh!important;
}
.notfound {
  width: 100%;
  line-height: 1.4;
  text-align: center;
  margin-top: 10%!important;
}
.notfound .notfound-404 {
  height: 150px;
  margin-bottom: 5px;
  /*z-index: -1;*/
}
@media only screen and (max-width: 480px){
  .notfound .notfound-404 h1 {
    font-size: 182px;
  }
}
.notfound .notfound-404 h1{
  font-family: 'Montserrat', sans-serif!important;
  position: absolute;
  left:50%;


    -webkit-transform: translate(-50% , -50%);
    -ms-transform: translate(-50% , -50%);
    transform: translate(-50% , -50%);
    font-size: 224px;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;
  text-align: center!important;
    color: #e70a80!important;
    text-transform: uppercase;
    text-shadow: -1px -1px 0px #8400ff, 1px 1px 0px #ff005a;
    letter-spacing: -20px;
  }

.error-header{
  font-family: 'Montserrat', sans-serif!important;

  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center!important;
  color: #e70a80!important;
  text-transform: uppercase;
  text-shadow: -1px -1px 0px #8400ff, 1px 1px 0px #ff005a;
  letter-spacing: -20px;
}
.error-subheader{
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px #8400ff;
  letter-spacing: 13px;
  font-family: 'Montserrat', sans-serif!important;
}

@media only screen and (max-width: 767px){
  .notfound .notfound-404 h2 {
    font-size: 24px;
    top: 180px;
  }
}
  .notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif!important;
    position: absolute;
    left: 0;
    right: 0;
    top: 280px;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 2px 0px #8400ff;
    letter-spacing: 13px;
    margin: 0;
    z-index: 999!important;
  }

 p {
  font-family: 'Poppins', sans-serif;
  margin: 5px 0 30px 0;
  line-height: 35px;
  font-size: 23px;
  text-align: center;
}
.mylink {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  text-transform: uppercase;
  color: #ff005a;
  text-decoration: none;
  border: 2px solid;
  background: transparent;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
</style>